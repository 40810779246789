import React from "react";
import { useMediaQuery } from "@material-ui/core";
import { useStyles } from "./HeaderLayoutProductTour.style";

interface LayoutProductTourProps {
  amountOfSteps: number;
  stepSelectedTop: number;
}

const HeaderLayoutProductTour = ({
  amountOfSteps,
  stepSelectedTop,
}: LayoutProductTourProps) => {
  const isMobile = useMediaQuery("(max-width: 1440px)");
  const classes = useStyles();

  const steps: any[] = [];

  for (let i = 0; i < amountOfSteps; i++) {
    steps.push(
      <div
        className={`${classes.staps} ${
          stepSelectedTop === i && classes.stepSelected
        }`}
      />
    );
  }

  return (
    <section>
      <div className={classes.containerSteps}>{steps}</div>
    </section>
  );
};

export default HeaderLayoutProductTour;
