import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  containerSteps: {
    display: "flex",
  },
  staps: {
    width: "2.4rem",
    height: "0.3rem",
    background: "#ffffff",
    borderRadius: 2.5,
    margin: "0 0.3rem",
    opacity: 0.4,
  },
  stepSelected: {
    opacity: 1,
  },
}));
