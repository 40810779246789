import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  containerFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down(900)]: {
      padding: "0rem 2rem 0rem ",
    },
    "& button": {
      fontSize: "1.2rem",
    },
    "& .whiteButton": {
      "& >button": {
        backgroundColor: "#ffffff",
        color: "#393e44 !important",
      },
    },
    [theme.breakpoints.down(800)]: {
      alignItems: "center",
    },
  },
  space: {
    width: 90,
  },
  containerSteps: {
    display: "flex",
    marginLeft: "3rem",
    [theme.breakpoints.down(800)]: {
      marginTop: "2rem",
      marginBottom: "6rem",
      marginLeft: "2rem",
    },
  },
  staps: {
    width: "0.4rem",
    height: "0.3rem",
    background: "#ffffff",
    borderRadius: 2.5,
    margin: "0 0.1rem",
    opacity: 1,
  },
  stepSelected: {
    width: "1.4rem",
  },
  button: {
    opacity: 1,
    color: "#ffffff",
    textDecoration: "none",
    fontSize: 12,
    "& svg": {
      marginLeft: 20,
    },
  },
  lastStepFlex: {
    display: "flex",
    alignItems: "center",
    height: "7rem",
    justifyContent: "space-around",
    flexDirection: "column",
    margin: "0 auto",
    "& div": {
      gap: 3,
      margin: "0 auto",
    },
  },
  buttonSecondary: {
    textDecoration: "none",
    opacity: 0.4,
  },
}));
