import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    justifyContent: 'space-evenly',
    background: '#00323C',
    gridTemplateColumns: '60% 40%',
    padding: '3rem 4rem',
    height: '100vh',
    [theme.breakpoints.down(1025)]: {
      display: 'flex',
      flexDirection: 'column',
      padding: '3rem 0rem',
      height: '100%',
    },
  },
  containerLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down(1025)]: {
      width: '100%',
    },
  },
  checkImage: {
    height: '9.8rem',
    marginBottom: '2.5rem',
    [theme.breakpoints.down(1025)]: {
      height: '6rem',
      marginBottom: '2rem',
    },
  },
  youAreReadyText: {
    color: '#FFFFFF',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    fontSize: '2.625rem',
    marginBottom: '3rem',
    [theme.breakpoints.down(1025)]: {
      fontSize: '1.5rem',
    },
  },
  descriptionText: {
    color: '#FFFFFF',
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: '1rem',
    marginBottom: '2rem',
    width: '25.6rem',
    textAlign: 'center',
    [theme.breakpoints.down(1025)]: {
      width: 'auto',
      fontSize: '0.875rem',
      padding: '0 1rem 0 1rem',
      marginBottom: '1rem',
    },
  },
  scheduleButton: {
    height: '3rem',
    width: '17rem',
    background: '#FFFFFF',
    boxShadow: '5px 5px 10px 3px rgba(0, 0, 0, 0.2)',
    borderRadius: '10.7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1.6rem',
    marginBottom: '5.8rem',
    '& span:': {
      color: '#00323C',
      fontFamily: 'Nunito',
      fontSize: '1rem',
    },
    [theme.breakpoints.down(1025)]: {
      marginBottom: '3rem',
    },
  },
  explorePlatformText: {
    color: '#FFFFFF',
    fontFamily: 'Nunito',
    fontSize: '0.75rem',
    marginBottom: '1.5rem',
    width: '18rem',
    [theme.breakpoints.down(1025)]: {
      width: 'auto',
      padding: '0 1rem 0 1rem',
      marginBottom: '0rem',
      marginTop: '1.5rem',
    },
  },
  goExploreRokinButton: {
    color: '#FFFFFF',
    width: '17rem',
    '& span': {
      color: '#FFFFFF',
    },
  },
  containerRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    gap: '1.5rem',
    [theme.breakpoints.down(1025)]: {
      width: '100%',
    },
  },
  containerUpperRight: {
    width: '100%',
    borderRadius: '16px',
    background: 'rgba(0, 129, 149, 0.5)',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  upperText: {
    color: '#FFFFFF',
    fontFamily: 'Nunito',
  },
  cardsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },
  goToServicesButton: {
    cursor: 'pointer',
  },
  goToServicesText: {
    color: '#FFFFFF',
    fontFamily: 'Nunito',
    fontSize: '1rem',
  },
  card: {
    width: '100%',
    minHeight: '3.375rem',
    padding: '0.5rem 0.75rem 0.5rem 1rem',
    background: '#00323C',
    borderRadius: '10px',
    boxShadow: '4px 4px 8px 2px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
    gap: '1rem',
    cursor: 'pointer',
  },
  gear: {
    width: '1.875rem',
    height: '1.875rem',
    background: 'rgba(0, 169, 199, 0.2)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '15px',
    boxShadow: '5px 5px 10px 3px rgba(0, 0, 0, 0.2)',
  },
  cardTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.2rem',
  },
  upperCardText: {
    color: '#FFFFFF',
    fontFamily: 'Nunito',
    fontSize: '0.875rem',
    fontWeight: 500,
  },
  lowerCardText: {
    color: '#FFFFFF',
    fontFamily: 'Nunito',
    fontSize: '0.625rem',
    fontWeight: 500,
  },
  scheduleText: {
    color: '#00323C',
    fontFamily: 'Nunito',
    fontSize: '1rem',
  },
}));
