import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    color: "#ffffff",
    background: "#00323C",
    height: "100vh",
    flexDirection: "column",
  },
  container: {
    display: "flex",
    justifyContent: "space-evenly",
    color: "#ffffff",
    alignItems: "center",
    [theme.breakpoints.down(1240)]: {
      flexDirection: "column",
      padding: "2rem",
    },
  },
  containerLeft: {
    display: "flex",
    flexDirection: "column",
    gap: "4rem",
    [theme.breakpoints.down(1240)]: {
      gap: "3.25rem",
      justifyContent: "center",
    },
    "& p": {
      fontFamily: "Montserrat",
      fontSize: "2rem",
      fontWeight: "bold",
      textAlign: "center",
      [theme.breakpoints.down(1240)]: {
        fontSize: "1.5rem",
      },
    },
  },
  imageContainer: {
    [theme.breakpoints.down(1240)]: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    "& img": {
      height: "23rem",
      [theme.breakpoints.down(1240)]: {
        height: "11rem",
        width: "11rem",
      },
    },
  },
  containerRight: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    "& .MuiInputLabel-filled": {
      color: "#F3F4F3",
    },
  },
  title: {
    fontFamily: "Montserrat",
    fontSize: "1.25rem",
    fontWeight: 500,
    textAlign: "center",
  },
  subtitle: {
    fontFamily: "Montserrat",
    fontSize: "0.875rem",
    textAlign: "center",
  },
  endtext: {
    fontFamily: "Nunito",
    fontSize: "0.625rem",
    textAlign: "center",
  },
  containerTexts: {
    height: "18rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "1rem",
    [theme.breakpoints.down(800)]: {
      height: "16rem",
    },
  },
  last: {},
  select: {
    "& .MuiInputLabel-filled.MuiInputLabel-shrink": {
      color: theme.palette.primary.main,
    },
    "& .MuiInputBase-input": {
      borderRadius: "1rem",
      color: theme.palette.background.paper,
    },
  },
  labelColor: {
    "& .MuiInputLabel-root.Mui-focused": {
      color: theme.palette.primary.main,
    },
  },
  button: {
    opacity: 1,
    color: "#ffffff",
    textDecoration: "none",
    fontSize: "1rem",
    "& svg": {
      marginLeft: 20,
    },
  },
  rutInput: {
    width: "100%",
    "& .MuiInputLabel-root": {
      color: theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "1rem",
      color: theme.palette.background.paper,
    },
  },
  containerRightStep2: {
    display: "flex",
    flexDirection: "column",
    width: "32rem",
    gap: "2rem",
    [theme.breakpoints.down(1240)]: {
      width: "auto",
    },
  },
  firstStepText: {
    fontSize: "1rem",
    fontFamily: "Montserrat",
    marginBottom: "1rem",
    [theme.breakpoints.down(1240)]: {
      marginBottom: "0.875rem",
      marginTop: "3.5rem",
    },
  },
  finantialTripText: {
    fontSize: "1.5rem",
    fontWeight: 500,
    fontFamily: "Montserrat",
    marginBottom: "2rem",
    [theme.breakpoints.down(1240)]: {
      marginBottom: "2.1rem",
      fontSize: "1rem",
    },
  },
  confidentialContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "1.74rem",
    "& p": {
      fontSize: "0.875rem",
      fontWeight: 500,
      fontFamily: "Nunito",
      [theme.breakpoints.down(1240)]: {
        fontSize: "0.75rem",
      },
    },
  },
  buttonDiv: {
    display: "flex",
    justifyContent: "flex-end",
  },
  skipHref: {
    display: "flex",
    justifyContent: "flex-end",
    opacity: 0.4,
    cursor: "pointer",
    fontSize: "1rem",
    padding: "0rem 7rem 3rem 0rem",
  },
  buttonMobile: {
    backgroundImage: "linear-gradient(85deg, #0a5669 6%, #0a5669 94%)",
    height: "3.18rem",
    padding: "1rem",
    color: "#FFF",
    fontFamily: theme.typography.fontFamily,
    fontWeight: 300,
    borderRadius: "10px",
    boxShadow: "4px 4px 8px 2px rgba(0, 0, 0, 0.2)",
    textTransform: "inherit",
    width: "100%",
  },
}));
