import React from 'react';
import { Button, useMediaQuery } from '@material-ui/core';
import { useStyles } from './FooterLayoutProductTour.style';
import { ButtonPrimary, ButtonText } from '@components/Reusables';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';

interface LayoutProductTourProps {
  amountOfSteps: number;
  stepSelected: number;
  onContinueClick: () => void;
  onOmitClick: () => void;
  disabledOmit?: boolean;
  setNumberStep?: (number: number) => void;
}

const FooterLayoutProductTour = ({
  amountOfSteps,
  onContinueClick,
  onOmitClick,
  stepSelected,
  disabledOmit,
  setNumberStep,
}: LayoutProductTourProps) => {
  const isMobile = useMediaQuery('(max-width: 1440px)');
  const classes = useStyles();

  const steps: any[] = [];

  for (let i = 0; i < amountOfSteps; i++) {
    steps.push(
      <div
        className={`${classes.staps} ${
          (stepSelected-2) === i && classes.stepSelected
        }`}
      />
    );
  }

  return (
    <section className={classes.containerFooter}>
      {stepSelected === 1 ? (
        <div className={classes.lastStepFlex}>
          <div className={classes.containerSteps}>{steps}</div>

          <div className='whiteButton'>
            <a href='#Topi'>
              <ButtonPrimary
                noDegraded
                text='Comenzar'
                onClick={onContinueClick}
              />
            </a>
          </div>
        </div>
      ) : (
        <>
          {stepSelected !== 4 && (
          <>
            <ButtonText
              onClick={() => {
                if (stepSelected < 2) {
                  setNumberStep!(0);
                } else {
                  setNumberStep!(stepSelected - 1);
                }
              }}
              className={`${classes.button} ${classes.buttonSecondary}`}
              text='Atrás'
            />            
            <div>
              <a href='#Topi'>
                <ButtonText
                  className={classes.button}
                  text={
                    <span>
                      Siguiente
                      <ArrowForwardIos />
                    </span>
                  }
                  onClick={onContinueClick}
                />
              </a>
            </div>
          </>
          )}    
        </>
      )}
    </section>
  );
};

export default FooterLayoutProductTour;
