import React, { useEffect, useState } from 'react';
import { Button, ButtonBase, useMediaQuery } from '@material-ui/core';
import { useStyles } from './CardDataProductTour.style';

import { getCompanies, updateUser, useCurrentUser } from '@apollo';
import { useForm } from '@hooks';
import { find } from 'lodash';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { RutInput } from '@components/Inputs';
import { ButtonPrimary, ButtonText } from '@components/Reusables';
import { COMPUTER_ROCKET } from 'images/GoogleCloud/Icons';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import { ArrowForwardIos } from '@material-ui/icons';
import { useApolloClient } from '@apollo/client';

interface CardDataProductTourProps {
  image: string;
  title: string | JSX.Element;
  subtitleBold: string;
  subtitle: string;
  step: number;
  setStep: (number: number) => void;
}

const CardDataProductTour = ({
  image,
  title,
  subtitleBold,
  subtitle,
  step,
  setStep,
}: CardDataProductTourProps) => {
  const isMobile = useMediaQuery('(max-width: 800px)');
  const classes = useStyles();
  const { user: currentUser } = useCurrentUser();
  const { companies } = getCompanies();
  const companiesOptions = companies.map((company) => ({
    value: company.id,
    name: company.name,
  }));

  const [selectedCompany, setSelectedCompany] = useState();
  const [form] = useForm({});
  const client = useApolloClient();
  const [rut, setRut] = useState<string>();
  const [isButtonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [isRutDisabled, setRutDisabled] = useState<boolean>(false);
  const [rutLabel, setRutLabel] = useState<string>('Ingresa tu RUT');

  useEffect(() => {
    if (rut) {
      if (rut.length >= 8) {
        form.rut = rut;
      }
    }
    const companySelected = find(companies, { id: selectedCompany });
    form.company = companySelected ?? null;
  }, [rut, selectedCompany]);

  const handleNextButton = () => {
    if (rut || selectedCompany) {
      updateUser(form, true, client);
    }
    setStep(step + 1);
  };

  companiesOptions.sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    if (rut) setButtonDisabled(false);
  }, [rut]);

  useEffect(() => {
    if (currentUser?.personalData.rut) {
      setRut(currentUser?.personalData.rut);
      setRutLabel('');
      setRutDisabled(true);
    }
  }, [currentUser]);

  return (
    <body className={classes.mainContainer}>
      {step == 1 && (
        <a className={classes.skipHref} href="/myFinances">
          Omitir evaluación
        </a>
      )}
      <div className={classes.container}>
        <div className={classes.containerLeft}>
          {step == 0 && <h1>Bienvenido a Rokin!</h1>}
          <div className={classes.imageContainer}>
            <img src={COMPUTER_ROCKET} alt="" />
          </div>
        </div>
        {step == 0 && (
          <div className={classes.containerRight}>
            <h2 className={classes.title}>
              ¿Tienes algún convenio para activar?
            </h2>
            <p className={classes.subtitle}>Empresa u otro</p>
            <FormControl variant="filled">
              <InputLabel
                id="selectCompanyLabel"
                className={classes.labelColor}
              >
                Selecciona tu empresa
              </InputLabel>
              <Select
                labelId="selectCompanyLabel"
                id="selectCompany"
                className={classes.select}
                value={selectedCompany}
                onChange={({ target: { value } }) => setSelectedCompany(value)}
              >
                <MenuItem value={undefined}>Ninguna</MenuItem>
                {companiesOptions.map((company) => (
                  <MenuItem value={company.value}>{company.name}</MenuItem>
                ))}
              </Select>
              <div style={{ height: '1.5rem' }} />
              <RutInput
                value={rut}
                onChange={(e) => setRut(e.target.value)}
                label={rutLabel}
                className={classes.rutInput}
                disabled={isRutDisabled}
              />
            </FormControl>
            <p className={classes.endtext}>
              Confirmaremos que pertenezcas al convenio para activar tus
              descuentos y beneficios.
            </p>
            <div className={classes.buttonDiv}>
              {isMobile ? (
                <Button
                  className={classes.buttonMobile}
                  onClick={() => handleNextButton()}
                  disabled={isButtonDisabled}
                >
                  Siguiente
                </Button>
              ) : (
                <ButtonText
                  className={classes.button}
                  text={
                    <span>
                      Siguiente
                      <ArrowForwardIos />
                    </span>
                  }
                  onClick={() => handleNextButton()}
                  disabled={isButtonDisabled}
                />
              )}
            </div>
          </div>
        )}
        {step == 1 && (
          <div className={classes.containerRightStep2}>
            <p className={classes.firstStepText}>¡Ya diste el primer paso!</p>
            <p className={classes.finantialTripText}>
              Para iniciar este viaje financiero, te haremos{' '}
              <strong>algunas preguntas</strong>, para saber cómo te podemos
              ayudar.
            </p>
            <div className={classes.confidentialContainer}>
              <GppGoodOutlinedIcon fontSize="medium" />
              <p>
                <strong>Y recuerda</strong>, toda la información que manejes con
                nosotros es
                <strong> 100% segura y confidencial</strong>. Está encriptada,
                nosotros ni tu empresa tendrá acceso a tus respuestas sin tu
                autorización.
              </p>
            </div>
            <div className={classes.buttonDiv}>
              {isMobile ? (
                <Button
                  className={classes.buttonMobile}
                  onClick={() => handleNextButton()}
                  disabled={isButtonDisabled}
                >
                  Siguiente
                </Button>
              ) : (
                <ButtonText
                  className={classes.button}
                  text={
                    <span>
                      Siguiente
                      <ArrowForwardIos />
                    </span>
                  }
                  onClick={() => handleNextButton()}
                  disabled={isButtonDisabled}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </body>
  );
};

export default CardDataProductTour;
