import React from "react";
import { useMediaQuery } from "@material-ui/core";
import { useStyles } from "./LayoutProductTour.style";
import HeaderLayoutProductTour from "./headerLayout/HeaderLayoutProductTour";
import FooterLayoutProductTour from "./footerLayout/FooterLayoutProductTour";
import { Footer } from "@components/Structural";
import { ButtonOutlined, ButtonText } from "@components/Reusables";

interface LayoutProductTourProps {
  children: React.ReactNode;
  step: number;
  amountSteps: number;
  onContinueClick: () => void;
  setNumberStep: (number: number) => void;
  onOmitClick: () => void;
  color: string;
  stepTop: number;
  omit?: boolean;
  disabledOmit?: boolean;
}

const LayoutProductTour = ({
  children,
  step,
  amountSteps,
  onContinueClick,
  setNumberStep,
  onOmitClick,
  color,
  stepTop,
  omit,
  disabledOmit,
}: LayoutProductTourProps) => {
  const isMobile = useMediaQuery("(max-width: 800px)");
  const classes = useStyles({ color: color, step: step });

  return (
    <>
      <section
        className={`${classes.containerLayout} ${
          step === 4 ? `${classes.last}` : ""
        }`}
      >
        <div className={classes.containerChildren}>
          <div className={classes.header} id="Topi">
            {(step !== 5) && (
              <>
                <HeaderLayoutProductTour
                  stepSelectedTop={stepTop}
                  amountOfSteps={amountSteps}
                />
                {omit && step !== 4 && (
                  <ButtonText
                    className={classes.buttonSecondary}
                    text="Omitir evaluación"
                    onClick={onOmitClick}
                  />
                )}
              </>
            )}
          </div>
          {children}
          <FooterLayoutProductTour
            stepSelected={step}
            setNumberStep={setNumberStep}
            amountOfSteps={amountSteps}
            onContinueClick={() => onContinueClick()}
            onOmitClick={onOmitClick}
            disabledOmit={disabledOmit}
          />
        </div>
      </section>
    </>
  );
};

export default LayoutProductTour;
